<template>
  <article class="my-info password-type">
    <h2>비밀번호 변경</h2>
    <div class="comm-tb-view">
      <table>
        <colgroup>
          <col style="width: 274px">
          <col style="width: auto">
        </colgroup>
        <tbody>
          <tr>
            <th>현재 비밀번호</th>
            <td>
              <div class="flex-l">
                <div class="text-wrap">
                  <input
                    v-model="password"
                    v-number
                    v-focus
                    type="password"
                    maxlength="6"
                    :class="!password ? '' : (validationPassword ? 'active' : 'error')"
                    @blur="matchPassword"
                  >
                </div>
                <div class="message-box mgl15">
                  <p
                    v-if="!validationPassword"
                    class="error-message"
                  >
                    비밀번호가 일치하지 않습니다.
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>새 비밀번호</th>
            <td>
              <div class="flex-l">
                <div class="text-wrap">
                  <input
                    ref="newPassword"
                    v-model="newPassword"
                    v-number
                    type="password"
                    maxlength="6"
                    :class="!newPassword ? '' : (validationNewPassword ? 'active' : 'error')"
                    @blur="checkNewPassword"
                  >
                </div>
                <div class="message-box mgl15">
                  <p
                    v-if="!validationNewPasswordLength"
                    class="error-message"
                  >
                    비밀번호는 6자리로 입력되어야 합니다.
                  </p>
                  <p
                    v-if="!validationNewPassword"
                    class="error-message"
                  >
                    현재 비밀번호와 동일합니다.
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>새 비밀번호 다시 입력</th>
            <td>
              <div class="flex-l">
                <div class="text-wrap">
                  <input
                    ref="checkPassword"
                    v-model="checkPassword"
                    v-number
                    type="password"
                    maxlength="6"
                    :class="!checkPassword ? '' : (validationCheckPassword ? 'active' : 'error')"
                    @keyup="matchNewPassword"
                  >
                </div>
                <div class="message-box mgl15">
                  <p
                    v-if="!validationCheckPassword"
                    class="error-message"
                  >
                    비밀번호가 일치하지 않습니다.
                  </p>
                </div>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <div class="btn-2-wrap mgt112">
      <button
        class="btn-next"
        @click="changePassword()"
      >
        변경하기
      </button>
    </div>
  </article>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      password: null,
      newPassword: null,
      checkPassword: null,
      validationPassword: true,
      validationNewPassword: true,
      validationCheckPassword: true,
      validationNewPasswordLength: true,
    }
  },
  watch: {
    password() {
      if (this.password.length === 6) this.$refs.newPassword.focus()
    },
    newPassword() {
      if (this.newPassword.length === 6) this.$refs.checkPassword.focus()
    }
  },
  methods: {
    matchPassword() {
      if (this.password !== null && this.password.length > 0) {
        axios.post('/fu/user/match/pw', {
          password: this.password,
        })
          .then(() => {
            this.validationPassword = true
          })
          .catch(() => {
            this.validationPassword = false
          })

        if (this.newPassword) this.checkNewPassword()
      }
    },
    checkNewPassword() {
      if (this.newPassword == null || this.newPassword.length !== 6) {
        this.validationNewPasswordLength = false
        return
      }

      if (this.newPassword.length === 6) this.validationNewPasswordLength = true

      if (this.password === this.newPassword) this.validationNewPassword = false
      else this.validationNewPassword = true

      if (this.checkPassword) this.matchNewPassword()
    },
    matchNewPassword() {
      if (this.newPassword === this.checkPassword) this.validationCheckPassword = true
      else this.validationCheckPassword = false
    },
    changePassword() {
      if (this.password == null || this.password.length === 0) this.$parent.showAlertDialog('현재 비밀번호를 입력해주세요.')
      else if (this.newPassword == null || this.newPassword.length === 0) this.$parent.showAlertDialog('새 비밀번호를 입력해주세요.')
      else if (this.checkPassword == null || this.checkPassword.length === 0) this.$parent.showAlertDialog('새 비밀번호를 다시 입력해주세요.')
      else if (this.newPassword.length !== 6 || this.checkPassword.length !== 6) this.$parent.showAlertDialog('비밀번호는 6자리로 입력되어야 합니다.')
      else if (this.validationPassword && this.validationNewPassword && this.validationCheckPassword) {
        axios.put('/fu/user/pw', {
          password: this.newPassword,
        })
          .then(() => {
            this.password = null
            this.newPassword = null
            this.checkPassword = null

            this.$parent.showAlertDialog('정상적으로 변경되었습니다.')
          })
          .catch(() => {
            this.$parent.showAlertDialog('비밀번호 변경 중 오류가 발생하였습니다.')
          })
      }
    },
  },
}
</script>

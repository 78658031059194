var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", { staticClass: "my-info password-type" }, [
    _c("h2", [_vm._v("비밀번호 변경")]),
    _c("div", { staticClass: "comm-tb-view" }, [
      _c("table", [
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("현재 비밀번호")]),
            _c("td", [
              _c("div", { staticClass: "flex-l" }, [
                _c("div", { staticClass: "text-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                      { name: "number", rawName: "v-number" },
                      { name: "focus", rawName: "v-focus" },
                    ],
                    class: !_vm.password
                      ? ""
                      : _vm.validationPassword
                      ? "active"
                      : "error",
                    attrs: { type: "password", maxlength: "6" },
                    domProps: { value: _vm.password },
                    on: {
                      blur: _vm.matchPassword,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "message-box mgl15" }, [
                  !_vm.validationPassword
                    ? _c("p", { staticClass: "error-message" }, [
                        _vm._v(" 비밀번호가 일치하지 않습니다. "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("새 비밀번호")]),
            _c("td", [
              _c("div", { staticClass: "flex-l" }, [
                _c("div", { staticClass: "text-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword",
                      },
                      { name: "number", rawName: "v-number" },
                    ],
                    ref: "newPassword",
                    class: !_vm.newPassword
                      ? ""
                      : _vm.validationNewPassword
                      ? "active"
                      : "error",
                    attrs: { type: "password", maxlength: "6" },
                    domProps: { value: _vm.newPassword },
                    on: {
                      blur: _vm.checkNewPassword,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newPassword = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "message-box mgl15" }, [
                  !_vm.validationNewPasswordLength
                    ? _c("p", { staticClass: "error-message" }, [
                        _vm._v(" 비밀번호는 6자리로 입력되어야 합니다. "),
                      ])
                    : _vm._e(),
                  !_vm.validationNewPassword
                    ? _c("p", { staticClass: "error-message" }, [
                        _vm._v(" 현재 비밀번호와 동일합니다. "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("th", [_vm._v("새 비밀번호 다시 입력")]),
            _c("td", [
              _c("div", { staticClass: "flex-l" }, [
                _c("div", { staticClass: "text-wrap" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkPassword,
                        expression: "checkPassword",
                      },
                      { name: "number", rawName: "v-number" },
                    ],
                    ref: "checkPassword",
                    class: !_vm.checkPassword
                      ? ""
                      : _vm.validationCheckPassword
                      ? "active"
                      : "error",
                    attrs: { type: "password", maxlength: "6" },
                    domProps: { value: _vm.checkPassword },
                    on: {
                      keyup: _vm.matchNewPassword,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.checkPassword = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "message-box mgl15" }, [
                  !_vm.validationCheckPassword
                    ? _c("p", { staticClass: "error-message" }, [
                        _vm._v(" 비밀번호가 일치하지 않습니다. "),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "btn-2-wrap mgt112" }, [
      _c(
        "button",
        {
          staticClass: "btn-next",
          on: {
            click: function ($event) {
              return _vm.changePassword()
            },
          },
        },
        [_vm._v(" 변경하기 ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "274px" } }),
      _c("col", { staticStyle: { width: "auto" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }